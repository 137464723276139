import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'
import { isUserLoggedIn } from '@utils'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

// I CAN READ REDUX HERE

const userDataJSON = localStorage.getItem('userData')
const userData = userDataJSON ? JSON.parse(userDataJSON) : null
let existingAbility = null
 console.log('valor en ability isUserLoggedIn()', isUserLoggedIn())
if (userData) {
    if (userData.role === 'admin') {
        existingAbility = [
            {
              action: 'manage',
              subject: 'all'
            }
          ]
    } else if (userData.role === 'consultor') {
    existingAbility = [
            {
              action: 'manage',
              subject: 'all'
            }
          ]
     }
}

console.log('ability on user: ', userData ? userData.ability : null)
console.log('initialAbility: ', initialAbility)

//const existingAbility = userData ? userData.ability : null

export default new Ability(existingAbility || initialAbility)

/*
import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'
import { useSelector } from 'react-redux'
//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const AbilityComponent = () => {

const userDataRedux = useSelector(state => state.userData)
console.log('userDataRedux ', userDataRedux)

const userData = JSON.parse(localStorage.getItem('userData'))
console.log('userData', userData)
const existingAbility = userDataRedux ? userDataRedux.ability : null

return new Ability(existingAbility || initialAbility)
}
export default AbilityComponent
*/