import { lazy } from 'react'

//const LiquidacionesEmpresas = lazy(() => import('../../views/pages/tasas-liquidaciones-carga'))
const CargaDeclaracionesEmpresas = lazy(() => import('../../views/pages/tasas-declaraciones-carga'))
const CargaIngresosEmpresas = lazy(() => import('../../views/pages/tasas-ingresos-ayto-carga'))

const gestionCargaTasasRoutes = [

    {
        path: '/tasas/carga/declaracion',
        element: <CargaDeclaracionesEmpresas />
    },
    {
        path: '/tasas/carga/ingresos',
        element: <CargaIngresosEmpresas />
    }
]

export default gestionCargaTasasRoutes
