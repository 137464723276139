// ** Icons Import
import { Circle, Briefcase, Database } from 'react-feather'

export default [
    {
        id: 'tasasCargaGestion',
        title: 'Datos',
        icon: <Database size={20} />,
        children: [
            {
                id: 'cargasFiles',
                title: 'Carga',
                icon: <Briefcase />,
                children: [
                    {
                        id: 'cargaDeclaraciones',
                        title: 'Declaraciones',
                        icon: <Circle size={12} />,
                        navLink: '/tasas/carga/declaracion'
                    },
                    {
                        id: 'cargaIngresos',
                        title: 'Ingresos',
                        icon: <Circle size={12} />,
                        navLink: '/tasas/carga/ingresos'
                    }
                ]
            }
        ]
    }
]