// ** Navigation imports
//import apps from './apps'
//import pages from './pages'
//import others from './others'
//import charts from './charts'
import inicio from './dashboards'
//import uiElements from './ui-elements'
//import formsAndTables from './forms-tables'
//import comercializadora from './comercializadora'
import distribuidora from './distribuidora'
import gestion from './gestion'
import carga from './carga'
import calendario from './calendario'
import gestionBackend from './gestionBackend'
//import inicioDashboards from './inicioDashboards' 

// ** Merge & Export
//export default [...inicio, ...distribuidora, ...gestion, ...carga, ...calendario, ...gestionBackend, ...inicioDashboards, ...apps, ...uiElements, ...formsAndTables, ...pages, ...charts, ...others]
export default [...inicio, ...distribuidora, ...gestion, ...carga, ...calendario, ...gestionBackend]
