// ** React Imports
import { lazy } from 'react'
//import { Navigate } from 'react-router-dom'

const InformePreliminar = lazy(() => import('../../views/apps/tasasConsultor'))

const TasasConsultorRoutes = [
  {
    element: <InformePreliminar />,
    path: '/apps/tasas',
    meta: {
      appLayout: true,
      className: 'email-application'
    }
  },
  {
    element: <InformePreliminar />,
    path: '/apps/tasas/infopremunicipiocom'
  }
]

export default TasasConsultorRoutes
