// ** Icons Import
import { FileText, Circle, Square, UserCheck } from 'react-feather'

  export default [
    {
      id: 'tasaspre',
      title: 'Estudios',
      icon: <FileText size={20} />,
      children: [
        {
          id: 'inforPreDistri',
          title: 'Distribuidoras',
          icon: <Circle size={12} />,
          navLink: '/tasas/distribuidora/informepre'
        },
        {
          id: 'inforPreComerc',
          title: 'Comercializadoras',
          icon: <Circle size={12} />,
          navLink: '/tasas/informeprecomercializadora'
        },
        {
          id: 'resumenInforPreTasas',
          title: 'Resumen Tasas',
          icon: <Circle size={12} />,
          navLink: '/tasas/resumen/informepre'
        }
      ]
    }
  ]