// ** Icons Import
import { Monitor, Activity, ShoppingCart } from 'react-feather'

export default [
  {
    id: 'inicioDash',
    title: 'Inicio',
    icon: <Activity />,
    navLink: '/dashboard/tasatool'
  },
  {
    id: 'preliminarTasas',
    title: 'Preliminar',
    icon: <Monitor />,
    navLink: '/tasas/preliminar/informe'
  }
]
