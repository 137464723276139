import { lazy } from 'react'

const VerUsuario = lazy(() => import('../../views/pages/tasas-gestion-usuarios-view'))
const VerUsuarioAdmin = lazy(() => import('../../views/pages/tasas-gestion-usuario-view'))
const VerAyuntamiento = lazy(() => import('../../views/pages/tasas-gestion-ayuntamiento-view'))
const CrearUsuario = lazy(() => import('../../views/pages/tasas-gestion-usuario-crear'))
const CrearAyuntamiento = lazy(() => import('../../views/pages/tasas-gestion-ayuntamiento-crear'))
const VerEmpresa = lazy(() => import('../../views/pages/tasas-gestion-empresa-view'))
const VerTuEmpresaConsultora = lazy(() => import('../../views/pages/tasas-gestion-tu-empresa'))
const VerContratoEmpresaConsultora = lazy(() => import('../../views/pages/tasas-servicios-consultor-view'))
const CrearContratoEmpresaConsultora = lazy(() => import('../../views/pages/tasas-servicios-consultor-crear'))


const GestionBackendRoutes = [
    {
        path: '/tasas/profile/usuarios/view',
        element: <VerUsuario />
    },    
    {
        path: '/tasas/profile/usuario/view',
        element: <VerUsuarioAdmin />
    },
    {
        path: '/tasas/profile/ayuntamiento/view',
        element: <VerAyuntamiento />
    },
    {
        path: '/tasas/profile/usuarios/crear',
        element: <CrearUsuario />
    },
    {
        path: '/tasas/profile/ayuntamiento/crear',
        element: <CrearAyuntamiento />
    },
    {
        path: '/tasas/profile/empresa/view',
        element: <VerEmpresa />
    },
    {
        path: '/tasas/profile/empresa/gestor/view',
        element: <VerTuEmpresaConsultora />
    },
    {
        path: '/tasas/profile/contratos/consultora/crear',
        element: <CrearContratoEmpresaConsultora />
    },
    {
        path: '/tasas/profile/contratos/consultora/view',
        element: <VerContratoEmpresaConsultora />
    }
] 

export default GestionBackendRoutes
