import { lazy } from 'react'

const InformeTasasRealPrevisto = lazy(() => import('../../views/pages/tasas-analisis-real-previsto'))
const LiquidacionesEmpresas = lazy(() => import('../../views/pages/tasas-liquidaciones'))
const DeclaracionesEmpresas = lazy(() => import('../../views/pages/tasas-declaraciones'))

//const InicioExpedienteTasas = lazy(() => import('../../views/pages/tasas-expedientes-inicio'))
const InicioExpedienteTasasAytos = lazy(() => import('../../views/pages/tasas-expedientes-inicio-aytos'))
//const SeguimientoExpedienteTasas = lazy(() => import('../../views/pages/tasas-expedientes-continuacion'))
const SeguimientoExpedienteTasasAytos = lazy(() => import('../../views/pages/tasas-expedientes-seguimiento-aytos'))
const VerExpedienteTasasAytos = lazy(() => import('../../views/pages/tasas-expedientes-view-aytos'))

const ContestacionExpedienteTasas = lazy(() => import('../../views/pages/tasas-expedientes-contestacion'))
const CrearContestacionExpedienteTasas = lazy(() => import('../../views/pages/tasas-expedientes-contestacion-crear'))


const gestionTasasRoutes = [
    {
        path: '/tasas/gestion/situacion',
        element: <InformeTasasRealPrevisto />
    },
    {
        path: '/tasas/gestion/liquidaciones',
        element: <LiquidacionesEmpresas />
    },
    {
        path: '/tasas/gestion/declaraciones',
        element: <DeclaracionesEmpresas />
    },
    /*{
        path: '/tasas/gestion/alta/expediente',
        element: <InicioExpedienteTasas />
    },*/
    {
        path: '/tasas/gestion/alta/expediente/ayto',
        element: <InicioExpedienteTasasAytos />
    },
  /*  {
        path: '/tasas/gestion/seguimiento/expediente',
        element: <SeguimientoExpedienteTasas />
    }, */
    {
        path: '/tasas/gestion/seguimiento/expediente/ayto',
        element: <SeguimientoExpedienteTasasAytos />
    },
    {
        path: '/tasas/gestion/listado/expediente/ayto',
        element: <VerExpedienteTasasAytos />
    },
    {
        path: '/tasas/gestion/contestacion/expediente',
        element: <ContestacionExpedienteTasas />
    },
    {
        path: '/tasas/gestion/contestacion/expediente/crear',
        element: <CrearContestacionExpedienteTasas />
    }
]

export default gestionTasasRoutes
