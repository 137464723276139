import { useState } from 'react'
// ** Third Party Components
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'

const IntlDropdown = () => {
  // ** Hooks
  const { i18n } = useTranslation()

  // ** Vars
  const langObj = {
    es: 'Español',
    ca: 'Catalá',
    eu: 'Euskera',
    gl: 'Galego',
    en: 'English',
    de: 'German',
    fr: 'French',
    pt: 'Portuguese'
  }

  const [selectedLanguage, setSelectedLanguage] = useState('es')

  // ** Function to switch Language
  const handleLangUpdate = (e, lang, country) => {
    e.preventDefault()
    setSelectedLanguage(country)
    i18n.changeLanguage(lang)
  }

  return (
    <UncontrolledDropdown href='/' tag='li' className='dropdown-language nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link' onClick={e => e.preventDefault()}>
        <ReactCountryFlag
          svg
          className='country-flag flag-icon'
          countryCode={selectedLanguage}
        />
        <span className='selected-language'>{langObj[i18n.language]}</span>
      </DropdownToggle>
      <DropdownMenu className='mt-0' end>
        <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'es', 'es')}>
          <ReactCountryFlag className='country-flag' countryCode='es' svg />
          <span className='ms-1'>Español</span>
        </DropdownItem>
        <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'ca', 'es-ct')}>
          <ReactCountryFlag className='country-flag' countryCode='es-ct' svg />
          <span className='ms-1'>Catalá</span>
        </DropdownItem>
        <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'eu', 'es-pv')}>
          <ReactCountryFlag className='country-flag' countryCode='es-pv' svg />
          <span className='ms-1'>Euskera</span>
        </DropdownItem>
        <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'gl', 'es-ga')}>
          <ReactCountryFlag className='country-flag' countryCode='es-ga' svg />
          <span className='ms-1'>Galego</span>
        </DropdownItem>
        <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'en', 'us')}>
          <ReactCountryFlag className='country-flag' countryCode='us' svg />
          <span className='ms-1'>English</span>
        </DropdownItem>
    {/*    <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'fr', 'fr')}>
          <ReactCountryFlag className='country-flag' countryCode='fr' svg />
          <span className='ms-1'>German</span>
        </DropdownItem>
        <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'de', 'de')}>
          <ReactCountryFlag className='country-flag' countryCode='de' svg />
          <span className='ms-1'>French</span>
        </DropdownItem>
        <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'pt', 'pt')}>
          <ReactCountryFlag className='country-flag' countryCode='pt' svg />
          <span className='ms-1'>Portuguese</span>
        </DropdownItem> */}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default IntlDropdown
