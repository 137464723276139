// ** Icons Import
import { FileText, Calendar, Box, Circle, Briefcase, Square, UserCheck } from 'react-feather'

export default [
    {
        id: 'tasasCalendario',
        title: 'Calendario',
        icon: <Box size={20} />,
        children: [
            {
                id: 'calendar',
                title: 'Calendario',
                icon: <Calendar />,
                navLink: '/tasas/calendario'
              }
        ]
    }
]