// ** Icons Import
import { BarChart2, FileText, Circle, FilePlus, File } from 'react-feather'

export default [
  {
    id: 'tasasgestion',
    title: 'Gestión',
    icon: <BarChart2 size={20} />,
    children: [
      {
        id: 'inforRealPrev',
        title: 'Análisis Ingresos',
        icon: <Circle size={12} />,
        navLink: '/tasas/gestion/situacion'
      },
      {
        id: 'tasasLiquidaciones',
        title: 'Liquidaciones',
        icon: <Circle size={12} />,
        navLink: '/tasas/gestion/liquidaciones'
      },
      {
        id: 'tasasDeclaraciones',
        title: 'Declaraciones',
        icon: <Circle size={12} />,
        navLink: '/tasas/gestion/declaraciones'
      },
      {
        id: 'expedientesgestion',
        title: 'Expedientes',
        icon: <File />,
        children: [
          /*      {
                  id: 'expedienteInicio',
                  title: 'Inicio',
                  icon: <FilePlus />,
                  navLink: '/tasas/gestion/alta/expediente'
                },*/
          {
            id: 'expedienteInicioAytos',
            title: 'Inicio',
            icon: <FilePlus />,
            navLink: '/tasas/gestion/alta/expediente/ayto'
          },
          /*         {
                     id: 'expedienteContinuacion',
                     title: 'Seguimiento',
                     icon: <Circle />,
                     navLink: '/tasas/gestion/seguimiento/expediente'
                   }, */
          {
            id: 'expedienteContinuacionAytos',
            title: 'Seguimiento',
            icon: <Circle />,
            navLink: '/tasas/gestion/seguimiento/expediente/ayto'
          },
          {
            id: 'expedienteListadoAytos',
            title: 'Listado',
            icon: <Circle />,
            navLink: '/tasas/gestion/listado/expediente/ayto'
          }
        ]
      },
      {
        id: 'contestacionesgestion',
        title: 'Contestaciones',
        icon: <FileText />,
        children: [
          {
            id: 'expedienteContestacion',
            title: 'Listado',
            icon: <Circle />,
            navLink: '/tasas/gestion/contestacion/expediente'
          },
          {
            id: 'expedienteCrearContestacion',
            title: 'Crear',
            icon: <FilePlus />,
            navLink: '/tasas/gestion/contestacion/expediente/crear'
          }
        ]
      }
    ]
  }
]