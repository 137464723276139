// ** Icons Import
import { Circle, Settings, UserCheck, Users, FilePlus, UserPlus, Home, List, FileText } from 'react-feather'

export default [
  {
    id: 'Backgestion',
    title: 'Administración',
    icon: <Settings size={20} />,
    children: [
      {
        id: 'gestionUsuarios',
        title: 'Usuarios',
        icon: <Users />,
        children: [
          {
            id: 'viewUsuario',
            title: 'Listado',
            icon: <List size={12} />,
            navLink: '/tasas/profile/usuarios/view'
          },
          {
            id: 'createUsuario',
            title: 'Crear',
            icon: <UserPlus size={12} />,
            navLink: '/tasas/profile/usuarios/crear'
          }
        ]
      },
      {
        id: 'gestionAyuntamientos',
        title: 'Ayuntamientos',
        icon: <Home />,
        children: [
          {
            id: 'viewAyuntamiento',
            title: 'Listado',
            icon: <List size={12} />,
            navLink: '/tasas/profile/ayuntamiento/view'
          },
          {
            id: 'createAyuntamiento',
            title: 'Crear',
            icon: <FilePlus size={12} />,
            navLink: '/tasas/profile/ayuntamiento/crear'
          }
        ]
      },
      {
        id: 'viewEmpresa',
        title: 'Empresas Energía',
        icon: <Circle size={12} />,
        navLink: '/tasas/profile/empresa/view'
      },
      {
        id: 'contratosConsultoras',
        title: 'Contratacion',
        icon: <FileText />,
        children: [
          {
            id: 'contratosView',
            title: 'Listado',
            icon: <List size={12} />,
            navLink: '/tasas/profile/contratos/consultora/view'
          },
          {
            id: 'contratosCreate',
            title: 'Crear',
            icon: <FilePlus size={12} />,
            navLink: '/tasas/profile/contratos/consultora/crear'
          }
        ]
      },
      {
        id: 'viewConsultoraEmpresa',
        title: 'Tu empresa',
        icon: <Circle size={12} />,
        navLink: '/tasas/profile/empresa/gestor/view'
      }
    ]
  }
]