import { lazy } from 'react'

//const LiquidacionesEmpresas = lazy(() => import('../../views/pages/tasas-liquidaciones-carga'))
const CalendarioTasas = lazy(() => import('../../views/pages/tasas-calendario'))

const calendarioTasasRoutes = [

    {
        element: <CalendarioTasas />,
        path: '/tasas/calendario'
    }
]

export default calendarioTasasRoutes
